import React from "react";
import { gql, useQuery } from "@apollo/client";
import styled from "styled-components";
import ActionBar from "../components/OldActionBar";
import Button from "../components/buttons/Button";
import { navigate } from "gatsby-link";
import Link from "../components/Link";
import PageWrapper from "../components/PageWrapper";
import AnalyticsIcon from "../components/icons/AnalyticsIcon";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_ALL_EVENTS = gql`
  query AllEvents {
    allEvents: getAllEvents {
      id
      name
      publicId
    }
  }
`;

const EventListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const EventListingWrapper = styled.div`
  font-size: 20px;
  margin-bottom: 15px;
`;

const AnalyticsLinkWrapper = styled.span`
  margin-left: 5px;
`;

export default function EventList() {
  const { data } = useQuery(GET_ALL_EVENTS);

  const allEvents = data && data.allEvents;

  const handleCreateEvent = () => {
    navigate("/create-edit-event");
  };

  return (
    <PageWrapper>
      <DarkPageHeading>Your Events</DarkPageHeading>
      <EventListContainer data-cy="event-list">
        {allEvents &&
          allEvents.map((event) => (
            <div key={event.id}>
              <EventListingWrapper data-cy="event">
                <Link to={`/create-edit-event?eventId=${event.id}`}>
                  {event.name}
                </Link>
                {event.started && (
                  <AnalyticsLinkWrapper>
                    <Link to={`/analytics?eventId=${event.id}`}>
                      <AnalyticsIcon size="small" />
                    </Link>
                  </AnalyticsLinkWrapper>
                )}
              </EventListingWrapper>
            </div>
          ))}
      </EventListContainer>
      <ActionBar>
        <Button
          size="large"
          label="Create an Event"
          onClick={handleCreateEvent}
        >
          Create an Event
        </Button>
      </ActionBar>
    </PageWrapper>
  );
}
