import styled from "styled-components";
import { LIGHT_REGULAR_TEXT } from "../colors";

export default styled.h1`
  font-size: 34px;
  line-height: 150%;
  margin-top: 0;
  margin-bottom: 30px;
  color: ${LIGHT_REGULAR_TEXT};
  text-align: center;
`;
